import React, { Component } from 'react'
import Routes from './routes';
import connect from 'utils/route_connect'
import LOADING_KEY from 'definitions/keys/loading.keys'
import SURVEY_KEYS from 'definitions/keys/survey.keys'
import { createBrowserHistory } from 'history';

class Main extends Component{
    render(){
        const history = createBrowserHistory();

        if (location.hash.startsWith('#/')) {
            window.location.href = `${location.hash.replace('#', '')}`;
            return;
        }
        
        return (
            <main>

                { Routes }

            </main>
        )
    }
}

export default connect(
    (state) => ({
        loading: state.loading[LOADING_KEY.app],
        showType: state.survey[SURVEY_KEYS.showType],
        vehicles: state.survey[SURVEY_KEYS.vehicles],
    })
)(Main)
