import {
    SET_SHOWTYPE,
    SET_VEHICLES,
    SET_PDF,
} from '../types'

import SURVEY_KEYS from 'definitions/keys/survey.keys'

const INITIAL_STATE = {
    [SURVEY_KEYS.showType]: null,
    [SURVEY_KEYS.vehicles]: null,
    [SURVEY_KEYS.pdf]: null,
};

const INITIAL_ACTION = { type: '' };

export default function(state=INITIAL_STATE, action=INITIAL_ACTION) {
    switch(action.type){

        case SET_SHOWTYPE:
            return {
                ...state,
                ...action.payload,
            };

        case SET_VEHICLES:
            return {
                ...state,
                ...action.payload,
            };

        case SET_PDF:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state
    }

}
