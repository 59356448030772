import React, { Fragment } from 'react'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import configureStore from 'state/store'

import App from './App'
import DevTools from './DevTools';

const store = configureStore();

export default function Root(){
    return (
        <Provider store={store}>
            <Fragment>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
                <DevTools />
            </Fragment>
        </Provider>
    )
}
