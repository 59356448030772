exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._Landing-module__container___1HFoD{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;text-align:center;width:100vw}._Landing-module__container___1HFoD h1{color:#F4F4F4;font-size:23px;margin:20px}._Landing-module__container___1HFoD h2{color:#F4F4F4;font-size:16px}._Landing-module__header___rZpOz{-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:30px 0;width:100%}._Landing-module__header___rZpOz img{height:40px}._Landing-module__vehicle_container___248WF{color:#4A4A4A;font-size:18px;margin:20px 0;width:93%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-pack:distribute;justify-content:space-around;-ms-flex-wrap:wrap;flex-wrap:wrap}._Landing-module__vehicle_container___248WF h2{width:100%}\n", ""]);

// exports
exports.locals = {
	"container": "_Landing-module__container___1HFoD",
	"header": "_Landing-module__header___rZpOz",
	"vehicle_container": "_Landing-module__vehicle_container___248WF"
};