import {
    SET_PAGE_VISIT,
    SET_LINK_CLICK,
} from '../types'

import TRACKING_KEYS from 'definitions/keys/tracking.keys'

const INITIAL_STATE = {
    [TRACKING_KEYS.pageVisited]: false,
    [TRACKING_KEYS.linkClicked]: false,
};

const INITIAL_ACTION = { type: '' };

export default function(state=INITIAL_STATE, action=INITIAL_ACTION) {
    switch(action.type){

        case SET_PAGE_VISIT:
            return {
                ...state,
                ...action.payload,
            };

        case SET_LINK_CLICK:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state
    }

}
