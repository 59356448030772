import ApiGroup from './ApiGroup'

export default class surveyAPI extends ApiGroup {
    constructor(){
        super({
            name: 'survey',
        });
    }

    fetch = ({ hashed_survey_id }) => (
        this.post({
            endpoint: `/fetch`,
            data: { hashed_survey_id },
        })
    );

    fetchVOI = ({ device_survey_guid }) => (
        this.post({
            endpoint: `/fetch/vehicles`,
            data: { device_survey_guid },
        })
    );

    trackPageVisits = ({ survey_id, visited, time }) => (
        this.post({
            endpoint: `/track/page/visits`,
            data: { survey_id, visited, time },
        })
    );

    trackLinkClicks = ({ survey_id, url, time }) => (
        this.post({
            endpoint: `/track/link/clicks`,
            data: { survey_id, url, time },
        })
    );

    downloadPDF = ({ file_name }) => (
        this.post({
            endpoint: `/download/pdf`,
            data: { file_name },
        })
    );

}
