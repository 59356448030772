import 'es6-shim'
import 'babel-regenerator-runtime'
import './assets/toImport'
import React from 'react'
import ReactDOM from 'react-dom'
import { ThroughProvider } from 'react-through'
import createPlugin from 'bugsnag-react'

import bugsnagClient from './utils/bugsnag_client'

const ErrorBoundary = bugsnagClient.use(createPlugin(React));
const rootElement = document.getElementById('root');

if (module.hot) {
    module.hot.accept('core/Root', () => {
        setTimeout(render, 0);
    });
}

render();

function render(){
    const Root = require('core/Root').default;
    ReactDOM.render(
        <ThroughProvider>
            <ErrorBoundary>
                <Root />
            </ErrorBoundary>
        </ThroughProvider>,
        rootElement
    );
}
