import {
    SET_LOADING,
} from '../types'

import LOADING_KEY from 'definitions/keys/loading.keys'

export function setAppLoading(isLoading){
    return setDataLoading(LOADING_KEY.app, isLoading)
}

export function setSurveyLoading(isLoading){
    return setDataLoading(LOADING_KEY.survey, isLoading)
}



function setDataLoading(key, isLoading){
    return {
        type: SET_LOADING,
        payload: {
            [key]: isLoading,
        },
    }
}
