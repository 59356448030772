import { combineReducers } from 'redux'
import { DESTROY_ALL } from '../types'

import loading from './loading.reducer'
import survey from './survey.reducer'
import tracking from './tracking.reducer'

const appReducer =  combineReducers({
    loading,
    survey,
    tracking,
});

const rootReducer = (state, action) => {
    if (action.type === DESTROY_ALL) {
        state = undefined
    }
    return appReducer(state, action)
};

export default rootReducer
