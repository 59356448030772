import React from 'react'

import {
    Switch,
    Route,
} from 'react-router-dom'

import Landing from 'pages/Landing/Landing.page'
import NotFound from 'pages/NotFound/NotFound.page'

export default (
    <Switch>

        <Route
            exact
            path="/:survey_id?"
            component={Landing}
        />

        <Route component={NotFound}/>

    </Switch>
);
