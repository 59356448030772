
// General Action Types
export const DESTROY_ALL = 'DESTROY_ALL';
export const SET_LOADING = 'SET_LOADING';
export const SET_SHOWTYPE = 'SET_SHOWTYPE';
export const SET_VEHICLES = 'SET_VEHICLES';
export const SET_PDF = 'SET_PDF';
export const SET_PAGE_VISIT = 'SET_PAGE_VISIT';
export const SET_LINK_CLICK = 'SET_LINK_CLICK';

