
/**
 * Survey Keys
 * ------------
 */
export default {
    showType: 'showType',
    vehicles: 'vehicles',
    pdf: 'pdf',
};
