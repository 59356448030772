import React from 'react'

import style from './_NotFound.module.scss'

function NotFound(){
    return (
        <div className={style.container}>
            <h3>Oops! This page could not be found</h3>
        </div>
    )
}

export default NotFound
