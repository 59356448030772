import React, { Component } from 'react'

import connect from 'utils/route_connect'
import LOADING_KEY from 'definitions/keys/loading.keys'
import SURVEY_KEYS from 'definitions/keys/survey.keys'
import style from './_Vehicle.module.scss'

class Vehicle extends Component {

    render(){
        const {
            v,
            show,
            site,
            trackClick,
            // buttonColor,
            teamID,
        } = this.props;

        const renderVehicleName = () => {
            const splitName = v.name.split(' ')
            if (v.name.includes('SUPER DUTY') && !v.name.includes('20')) {
                return (
                    <p>{`${splitName[0]} ${splitName[1]}`}<sup>&reg;</sup>{` ${splitName[2]}`}</p>
                )
            } else if (v.name.includes('SUPER DUTY') && v.name.includes('20')) {
                return (
                    <p>{`${splitName[0]} ${splitName[1]} ${splitName[2]}`}<sup>&reg;</sup>{` ${splitName[3]}`}</p>
                )
            } else {
                return (
                    <p>{v.name}</p>
                )
            }
        }

        return (
            <div key={v.id} className={style.vehicle_wrap}>
                {renderVehicleName()}
                <img className={style.image} src={v.image}  alt={v.name}/>
                {v.url
                    ? <a
                        className={teamID === 1 ? style.lincolnButton : style.fordButton}
                        href={show ? site : `${v.url}`}
                        target={'_blank'}
                        onClick={() => {trackClick(`${v.url}`)}}
                    >
                        More Information
                    </a>
                    : null
                }

                {/* {v.pdf
                    ? <a
                        style={{backgroundColor: buttonColor}}
                        href={`${v.pdf}`}
                        target={'_blank'}
                        onClick={() => {trackClick(`${v.pdf}`)}}
                    >
                        Download Brochure
                    </a>
                    : null
                } */}
            </div>

        )
    }
}

export default connect(
    (state) => ({
        loading: state.loading[LOADING_KEY.app],
        showType: state.survey[SURVEY_KEYS.showType],
        vehicles: state.survey[SURVEY_KEYS.vehicles],
    })
)(Vehicle)
