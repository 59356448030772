import {
    SET_PAGE_VISIT,
    SET_LINK_CLICK,
} from '../types'

import TRACKING_KEYS from 'definitions/keys/tracking.keys'

export function trackPageVisit(survey_id, visited, time) {
    return function(dispatch, API) {
        API.survey.trackPageVisits({ survey_id, visited, time })
            .then(({ data }) => {
                dispatch(setPageVisit(data))
            })
            .catch(response =>{
                console.log(response);
            });
    }
}


export function trackLinkClick(survey_id, url, time) {
    return function(dispatch, API) {
        API.survey.trackLinkClicks({survey_id, url, time})
            .then(({ data }) => {
                dispatch(setLinkClicks(data));
            })
            .catch(response =>{
                console.log(response);
            });
    }
}

export function setPageVisit(data){
    return setVisitData(TRACKING_KEYS.pageVisited, data)
}

function setVisitData(key, data){
    return {
        type: SET_PAGE_VISIT,
        payload: {
            [key]: data,
        },
    }
}

export function setLinkClicks(data){
    return setLinkClickData(TRACKING_KEYS.linkClicked, data)
}

function setLinkClickData(key, data){
    return {
        type: SET_LINK_CLICK,
        payload: {
            [key]: data,
        },
    }
}

