import React, { Component } from 'react'
import _ from 'lodash'

import connect from 'utils/route_connect'
import LOADING_KEY from 'definitions/keys/loading.keys'
import SURVEY_KEYS from 'definitions/keys/survey.keys'
import FordFooter from 'react-ford-ccpa-banner';

import {
    trackLinkClick,
    trackPageVisit,
} from '../../state/actions/tracking.actions'
import {
    getPDF,
    getVOI,
    getSurvey,
} from '../../state/actions/survey.actions'

import style from './_Landing.module.scss'
import vehicleStyle from '../../components/Vehicle/_Vehicle.module.scss'
import Vehicle from '../../components/Vehicle'
import { allFord, allLincoln, allVehicles } from '../../common/vehicles';

const fordStyle = {
    color: '#00142E',
    buttonColor: '#00095B',
    textColor: '#00095B',
    fontFamily: 'FordF1',
    logo: require('../../assets/images/logos/ford_signature_white.png'),
    altLogo: 'Ford',
};

const lincolnStyle = {
    color: '#324047',
    buttonColor: '#324047',
    textColor: '#324047',
    fontFamily: 'ProximaNovaRgRegular',
    logo: require('../../assets/images/logos/LincolnLogo_2019_White.png'),
    altLogo: 'Lincoln',
};

class LandingPage extends Component {

    url = window.location.href;
    state = {
        brandVehicles: [],
        voiVehicles: [],
        show: '',
        site: '',
    };

    componentDidMount(){

        const {
            loading,
            match: {
                params: { survey_id },
            },
            getSurvey,
            getVOI,
            trackPageVisit,
            history,
        } = this.props;

        if (!survey_id) {
            // no survey, no website for you.
            if(this.url.indexOf('lincolnshowtracker') > -1){
                window.location.href = 'https://lincoln.com/';
            }
    
            if(this.url.indexOf('fordshowtracker') > -1){
                window.location.href = 'https://ford.com/';
            }
        }

        if(!loading) {
            getSurvey(survey_id)
                .then(success => {

                    getVOI(success.device_survey_guid)
                        .then(() => {
                            this.setViewData();
                            trackPageVisit(success.survey_id, 1, new Date());
                        })

                })
                .catch(() => {
                    // no survey, no website for you.
                    if(this.url.indexOf('lincolnshowtracker') > -1){
                        window.location.href = 'https://lincoln.com/';
                    }
            
                    if(this.url.indexOf('fordshowtracker') > -1){
                        window.location.href = 'https://ford.com/';
                    }
                });
        }
    }

    setViewData = (props = this.props) => {
        this.setVehicles(props);

        // const {
        //     showType,
        // } = props;

        // const showIDs = [
        //     /* LA */
        //     set LA links to behave like normal shows, no special auto show pages
        //     1380,
        //     1634,
        //
        //     /* New York */
        //     1438,
        //     1692,
        //     /* Detroit */
        //     1389,
        //     1643,
        // ];

        // if (showType && _.includes(showIDs, showType.show_id)) {
        //     this.setShowType(showType.show_id)
        // }
    };

    getBrandForURL = () => {
        if(this.url.indexOf('lincolnshowtracker') > -1){
            return 1
        }

        if(this.url.indexOf('fordshowtracker') > -1){
            return 5
        }
    };

    setVehicles = (props = this.props) => {
        const brandVehicles = this.getBrandVehicles(props);

        this.setState({ brandVehicles }, () => {

            const voiVehicles = this.getVOIVehicles(props);

            this.setState({ voiVehicles });

        });
    };

    getBrandVehicles = (props = this.props) => {
        let team_id = props.showType.team_id;

        if(!team_id){
            team_id = this.getBrandForURL();
        }
        switch (team_id) {
            case 1: return  allLincoln;
            case 5: return  allFord;
            default: return  allVehicles;
        }
    };

    getVOIVehicles = (props = this.props) => {

        if(!this.state.brandVehicles.length) return [];

        const {
            vehicles,
        } = props;

        let voiVehicles = [];

        if (vehicles && vehicles.length) {
            voiVehicles = (
                [...vehicles]
                    .map(v => _.find(this.state.brandVehicles, {'id': v.vehicle_id}))
                    .filter(v => v)
            );


        }
        return voiVehicles;
    };

    openPDF = (file_name) => {
        const {getPDF} = this.props;
        getPDF(file_name)
            .then(url => {
                window.open(url.data, '_blank')
            })
            .catch(error => {
                console.log('[Vehicles.js error] -', error);
            })
    };

    trackClick = (link) => {

        const {
            trackLinkClick,
            showType: {
                survey_id,
            },
        } = this.props;

        if(!survey_id) return;

        const now = new Date();

        trackLinkClick(survey_id, link, now)
    };

    // setShowType = (show_id) => {
    //     switch (show_id) {
    //         // TODO: get autoshow site links
    //
    //         // set LA links to behave like normal shows, no special auto show pages
    //         case 1380:
    //             this.setState({
    //                 show: 'LA',
    //                 site: 'https://laautoshow.com/',
    //             });
    //             break;
    //
    //         case 1634:
    //             this.setState({
    //                 show: 'LA',
    //                 site: 'https://laautoshow.com/tickets/?utm_source=main-nav&utm_medium=website&utm_campaign=main-nav-tickets-button',
    //             });
    //             break;
    //
    //         case 1438:
    //             this.setState({
    //                 show: 'NY',
    //                 site: 'https://www.autoshowny.com/',
    //             });
    //             break;
    //
    //         case 1692:
    //             this.setState({
    //                 show: 'NY',
    //                 site: 'https://www.autoshowny.com/',
    //             });
    //             break;
    //
    //         case 1389:
    //             this.setState({
    //                 show: 'DTR',
    //                 site: 'https://naias.com/',
    //             });
    //             break;
    //
    //         case 1643:
    //             this.setState({
    //                 show: 'DTR',
    //                 site: 'https://naias.com/',
    //             });
    //             break;
    //
    //         default:
    //             break
    //     }
    // };


    render() {
        const {
            brandVehicles,
            voiVehicles,
            show,
            site,
        } = this.state;

        const {
            loading,
            showType:{
                team_id,
            },
        }= this.props;

        if(loading){
            return (
                <div>
                    Loading...
                </div>
            )
        }

        let teamId = team_id;

        if(!teamId){
            teamId = this.getBrandForURL();
        }

        let brandStyle = fordStyle;

        if(teamId === 1){
            brandStyle = lincolnStyle;
        }

        const {
            color,
            buttonColor,
            textColor,
            logo,
            altLogo,
            fontFamily
        } = brandStyle;

        const specialVehicles = brandVehicles.filter(({ specialVehicle }) => specialVehicle);
        const filteredBrandVehicles = brandVehicles.filter(({ specialVehicle }) => !specialVehicle);

        return (
            <div className={style.container} style={{backgroundColor: '#F4F4F4', fontFamily: fontFamily}}>
                <div className={style.header} style={{backgroundColor: color}}>
                    <img src={logo} alt={altLogo} />
                </div>
                <h1 style={{ color: textColor }}>Vehicle Information</h1>

                {!specialVehicles.length && !voiVehicles.length
                    ? null
                    : <div className={style.vehicle_container}>
                        {specialVehicles.map(v => {
                            return (
                                <div key={v.id} className={vehicleStyle.mache_wrap} >
                                    {v.url
                                        ? <a
                                            style={{backgroundColor: buttonColor}}
                                            href={show ? site : `${v.url}`}
                                            target={'_blank'}
                                            onClick={() => {this.trackClick(`${v.url}`)}}
                                        >
                                        Reserve a new Mustang Mach-E now!
                                        </a >
                                        : null
                                    }
                                </div >
                            )
                        })}

                        {voiVehicles.length
                            ? <h2 style={{color: textColor}} >Your Selected Vehicles</h2 >
                            : null
                        }

                        {voiVehicles.map(v => {
                            return (
                                <Vehicle
                                    key={v.id}
                                    v={v}
                                    show={show}
                                    site={site}
                                    openPDF={this.openPDF}
                                    trackClick={this.trackClick}
                                    buttonColor={buttonColor}
                                    teamID={teamId}
                                />
                            )
                        })}
                    </div >
                }

                <div className={style.vehicle_container} >
                    {voiVehicles.length
                        ? <h2 style={{ color: textColor }}>Other Vehicles</h2>
                        : null
                    }
                    {filteredBrandVehicles.map(v => {

                        if(voiVehicles.find(({ id }) => v.id === id)){
                            return null;
                        }

                        return (
                            <Vehicle
                                key={v.id}
                                v={v}
                                show={show}
                                site={site}
                                openPDF={this.openPDF}
                                trackClick={this.trackClick}
                                buttonColor={buttonColor}
                                teamID={teamId}
                            />
                        )
                    })}
                </div >
                <FordFooter
                    showPrivacy={true}
                    language={'en'}
                    team={teamId === 1 ? 'lincoln' : 'ford'}
                />
            </div >
        )
    }
}


export default connect(
    (state) => ({
        loading: state.loading[LOADING_KEY.survey],
        showType: state.survey[SURVEY_KEYS.showType] || {},
        vehicles: state.survey[SURVEY_KEYS.vehicles],
    }), {
        getPDF,
        getVOI,
        getSurvey,
        trackLinkClick,
        trackPageVisit,
    }
)(LandingPage)
