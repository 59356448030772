import React, { Component } from 'react'

import Main from './Main/Main'

class App extends Component {

    render(){
        return (
            <div className="app">

                {/* Header? */}

                <Main />

                {/* Footer? */}

            </div>
        )
    }
}

export default App
