export const basePath = `/microsite/v1`;
export const publicBasePath = `/`;

export const getApiURL = (ENV=process.env.NODE_ENV, path=basePath) => {

    // default to SHOWTRACKER API
    let apiDomain = process.env.SHOWTRACKER_API_DOMAIN;

    // use PFG API
    if (window.location.href.indexOf('?=fe') > -1) {
        apiDomain = process.env.PFG_API_DOMAIN;
    }

    // use DTG API
    if (window.location.href.indexOf('?=le') > -1) {
        apiDomain = process.env.GTB_LINCOLN_API_DOMAIN;
    }

    const domain = `${apiDomain}${path}`;
    const isLocalhost = apiDomain.indexOf('localhost') > -1;
    let protocol = 'https://';

    if(isLocalhost || (ENV !== 'staging' && ENV !== 'production')){
        protocol = 'http://';
    }

    return `${protocol}${domain}`;
};

export const getPublicApiURL = (ENV=process.env.NODE_ENV, path=publicBasePath) => {
    // default to SHOWTRACKER API
    let apiDomain = process.env.SHOWTRACKER_API_DOMAIN;

    const domain = `${apiDomain}${path}`;
    const isLocalhost = apiDomain.indexOf('localhost') > -1;

    let protocol = 'https://';

    if(isLocalhost || (ENV !== 'staging' && ENV !== 'production')){
        protocol = 'http://';
    }

    return `${protocol}${domain}`;
};

export const API_URL = getApiURL();
export const PUBLIC_API_URL = getPublicApiURL();
