import {
    SET_SHOWTYPE,
    SET_VEHICLES,
    SET_PDF,
} from '../types'

import { setSurveyLoading } from './loading.actions'

import SURVEY_KEYS from 'definitions/keys/survey.keys'
import LOADING_KEYS from 'definitions/keys/loading.keys'

export function getSurvey(hashed_survey_id) {
    return function(dispatch, API, getState) {

        if(!getState().loading[LOADING_KEYS.survey]){
            dispatch(setSurveyLoading(true))
        }

        return API.survey.fetch({hashed_survey_id})
            .then(({ data }) => {
                dispatch(setShowType(data[0]));
                dispatch(setSurveyLoading(false));
                return Promise.resolve(data[0])
            })
            .catch(error =>{
                console.log(error);
                dispatch(setSurveyLoading(false));
                return Promise.reject(error);
            });
    }
}


export function getVOI(device_survey_guid) {
    return function(dispatch, API, getState) {

        if(!getState().loading[LOADING_KEYS.survey]){
            dispatch(setSurveyLoading(true))
        }
        return API.survey.fetchVOI({device_survey_guid})
            .then(({ data }) => {
                dispatch(setVehicles(data));
                dispatch(setSurveyLoading(false));
                return Promise.resolve(data)
            })
            .catch(response =>{
                console.log(response);
                dispatch(setSurveyLoading(false));
                return Promise.resolve();
            });
    }
}

export function getPDF(file_name) {
    return function(dispatch, API) {
        return API.survey.downloadPDF({file_name})
            .then(({ data }) => {
                dispatch(setPDF(data));
                return Promise.resolve(data)
            })
            .catch(response =>{
                console.log(response);
                return Promise.reject();
            });
    }
}

export function setShowType(showType){
    return setShowData(SURVEY_KEYS.showType, showType)
}

function setShowData(key, showType){
    return {
        type: SET_SHOWTYPE,
        payload: {
            [key]: showType,
        },
    }
}

export function setVehicles(vehicles){
    return setVehiclesData(SURVEY_KEYS.vehicles, vehicles)
}

function setVehiclesData(key, vehicles){
    return {
        type: SET_VEHICLES,
        payload: {
            [key]: vehicles,
        },
    }
}

export function setPDF(file_name){
    return setPDFData(SURVEY_KEYS.pdf, file_name)
}

function setPDFData(key, file_name){
    return {
        type: SET_PDF,
        payload: {
            [key]: file_name,
        },
    }
}

